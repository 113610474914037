const storage = {
    // 修改
    set(key, value) {
        if (typeof value === "object") {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    },
    // 获取
    get(key) {
        const data = localStorage.getItem(key);
        try {
            return JSON.parse(data);
        } catch (err) {
            return data;
        }
    },
    // 删除
    remove(key) {
        localStorage.removeItem(key);
    },
};

export default storage;
