<template>
  <transition name="el-zoom-in-bottom">
    <div class="MediaPlayer" v-show="this.$store.state.MediaPlayer_show">
      <div class="backgroundImg" :style="' background-image: url('+this.$store.state.songImg+')'"></div>

      <div class="header">
        <div class="hader-titile">
          <span>{{ music_data.songs[0].name }}</span>
          <span @click="goto(music_data.songs[0].ar[0].id)">{{ music_data.songs[0].ar[0].name }}</span>
        </div>
        <div class="hader-button">
          <span><i class="el-icon-close" @click="MediaPlayer_show"></i></span>
        </div>
      </div>

      <div class="MediaPlayerBox" :class="active_img?'active_img':''">
        <div class="lyric_left">
          <canvas class="PlayerCanvas"></canvas>
          <el-image
              :src="(music_data.songs[0].al.picUrl==null)? music_data.songs[0].al.picUrl:this.$store.state.songImg"
              :style="'transform: rotate('+content+'deg)'"></el-image>
        </div>

        <div class="lyric_right">
          <!--          <span><label>专辑：</label>{{music_data.songs[0].al.name}}</span>-->
          <div class="lyrics_box">
            <p v-show="!lyrics_boxShow">歌词正在赶来的路上 (✪ω✪)</p>
            <ul class="lrc" v-show="lyrics_boxShow">
              <li v-for="(item,index) in lrcData" :key="index" :style="item.words==''?'height: 35px':''">
                {{ item.words }}
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import {song_detail, lyric} from '@/utils/api.js'

export default {
  name: "MediaPlayer",
  data() {
    return {
      songId: '',
      content: 0,
      lyrics_boxShow: false,
      active_img: false,
      music_data: {
        songs: [{
          ar: [{}],
          al: {picUrl: ''}
        }]
      },
      lrcData: {},
    }
  },
  methods: {
    MediaPlayer_show() {
      this.$store.state.MediaPlayer_show = false
    },
    goto(id) {
      let urlS = '/artistDetail/' + id
      this.$router.push(urlS)
      this.$store.state.MediaPlayer_show = false
    },

    get_song_detail(songId) {

      song_detail({'ids': songId}).then(res => {
        this.music_data.songs = res.songs
      })
    },

    get_lyric(songId) {
      this.$store.state.lrcTextData = {}
      this.lrcData = {}
      this.lyrics_boxShow = false

      let lrcData

      lyric({'id': songId}).then(res => {
        this.lyrics_boxShow = true
        this.$store.state.lrcTextData = res.lrc.lyric

        const lrc = res.lrc.lyric
        lrcData = lrc.split('\n').filter((s) => s).map((s) => {
          const parts = s.split(']');
          const timeParts = parts[0].replace('[', '').split(':');
          return {
            time: +timeParts[0] * 60 + +timeParts[1],
            words: parts[1],
          };
        })

        this.lrcData = lrcData
        // dooms.ul.innerHTML = lrcData.map((lrc) => `<li>${lrc.words}</li>`).join('');
      })
    },


    lrcList(lrcData) {
      let index = 0

      const dooms = {
        audio: '',
        ul: '', //歌词展示的ul
        ovfl: '',
        text: ''
      }
      // let songTime = this.$store.state.songTime
      let i = setTimeout(() => {
        dooms.audio = document.querySelector('audio') //audio
        dooms.ul = document.querySelector('.lrc')
        dooms.ovfl = document.querySelector('.ovf')
        if (dooms.audio !== null) {
          dooms.audio.addEventListener('timeupdate', function () {
            setStatus(this.currentTime)
          });
        }
        clearInterval(i)
      }, 100)


      const size = {
        liHeight: 35,
        containerHeight: 0,
      };
      const ovf = {
        ovfHeigth: 30,
        containerHeight: 0
      };

      function setStatus(time) {
        if (document.documentElement.clientWidth > 800) {
          size.containerHeight = 595
          ovf.containerHeight = 595
        } else {
          size.containerHeight = 105
          ovf.containerHeight = 105
        }

        // 微调
        time += 0.5;
        //1. 根据时间找到对应的li，高亮
        // 消除之前的active
        const activeLi = document.querySelector('.active');
        activeLi && activeLi.classList.remove('active');
        index = lrcData.findIndex((lrc) => lrc.time > time) - 1; //得到当前音乐对应的歌词

        if (index < 0) {
          return;
        }
        if (dooms.ul.children[index]) {
          dooms.ul.children[index].classList.add('active');
        } else {
          index = 0
        }
        //2. 设置ul的滚动位置
        let top =
            size.liHeight * index + size.liHeight / 2 - size.containerHeight / 2;
        top = -top;
        if (top > 0) {
          top = 0;
        }

        let ovfh = ([ovf.containerHeight - ovf.ovfHeigth] / lrcData.length) * index

        //设置滚动的大小
        dooms.ul.style.transform = `translateY(${top}px)`;
        // dooms.ovfl.style.top = `${ovfh}px`

      }

    },

  },
  created() {
    this.songId = this.$store.state.songId

    this.get_song_detail(this.songId)
    this.get_lyric(this.songId)

    let i = setInterval(() => {
      if (this.lrcData.length > 0) {
        this.lrcList(this.lrcData)
        clearInterval(i)
      }
    }, 100)

  }
  ,
  watch: {
    '$store.state.songId':
        {
          deep: true,
          //监听store的歌曲id变换
          handler: function () {
            this.get_song_detail(this.$store.state.songId)
            this.get_lyric(this.$store.state.songId)

            let i = setInterval(() => {
              if (this.lrcData.length > 0) {
                this.lrcList(this.lrcData)
                clearInterval(i)
              }
            }, 100)
          }
        }
  }
}
</script>

<style scoped>
.MediaPlayer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  z-index: 99;
  background-color: #c0c0c0;
  transition: linear 0.25s;
}

.backgroundImg {
  width: 100vw;
  height: 100vh;
  background-size: 100% !important;
  background-position: 50%;
  position: fixed;
  top: 0;
  filter: blur(100px);
  z-index: -999999999999;
}

.header {
  background: rgba(255, 255, 255, 0.45);
  text-align: center;
  padding: 5px 10px;
}

.header .hader-titile,
.header .hader-button {
  font-size: 20px;
  display: inline;
  color: #000000;
}

.hader-titile {
  user-select: none;
  width: 90%;
  display: inline-block !important;
  text-align: center;
}

.hader-titile span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hader-titile span:nth-child(1) {
  max-width: 60%;
  margin: 0 8px;
}

.hader-titile span:nth-child(2) {
  max-width: 30%;
  font-size: 15px;
  color: #818181;
  cursor: pointer;
}

.hader-button {
  cursor: pointer;
  float: right;
}

.MediaPlayerBox {
  height: calc(100vh - 36px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.lyric_left {
  width: 400px;
  height: 400px;
  min-width: 400px;
  min-height: 400px;
  transition: 0.3s linear;
  position: relative;
  display: block;
}

.PlayerCanvas {
  width: 610px;
  height: 610px;
  position: absolute;
  bottom: 105px;
  right: 105px;
  transform: translate(210px, 210px);
}

.lyric_left .el-image {
  /*opacity: 0.12;*/
  width: 100%;
  height: 100%;
  border-radius: 500px;
  box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.25),
  0 0 0 15px rgba(255, 255, 255, 0.1);
  transition: 0.5s linear;
}

.active_img .el-image {
  /*animation:  20s active-img linear infinite;*/
}


.lyric_right {
  flex: 0.6;
  height: 595px;
  border-radius: 20px;
  padding: 0 15px;
  /*overflow-y: auto;*/
  overflow: hidden;
  position: relative;
}

.lyric_right p {
  text-align: center;
  font-size: 26px;
  color: #ffffff;
}

.lyric_right ul {
  margin: 0;
  padding: 0;
  line-height: 20px;
  text-align: center;
  user-select: none;
  list-style: none;
  transition: linear 0.25s;
}

.lyric_right li {
  color: rgba(255,255,255,0.75);
  line-height: 35px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.15s ease-in-out;
}

.lyric_right .active {
  color: #409eff;
  font-weight: 700;
  height: auto;
  white-space: break-spaces;
  transform: scale(1.1);
}

/*@keyframes active-img {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

@media screen and (max-width: 800px) {
  .MediaPlayerBox {
    flex-direction: column;
  }

  .lyric_left {
    width: 240px;
    height: 240px;
    min-width: 240px;
    min-height: 240px;
  }

  .PlayerCanvas {
    width: 450px;
    height: 450px;
    bottom: 105px;
    right: 105px;
    transform: translate(210px, 210px);
  }

  .lyric_right {
    flex: 0.95;
    max-width: 90%;
    flex: none;
    height: 105px;
    background-color: transparent;
    box-shadow: none;
  }

}
</style>
