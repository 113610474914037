<template>
  <div class="search">
    <div class="input-box">
      <el-input
          placeholder="请输入内容"
          v-model="inputText" @input="getSearch($event)" @blur="box_show(false)" @focus="box_show(true)">
        <i slot="suffix" class="i_search el-input__icon el-icon-search" @click="goto('songsearch',inputText)"></i>
      </el-input>
    </div>

    <transition name="el-zoom-in-top">

      <div class="text-box" v-show="text_box">
        <p class="note s-fc3">
          <router-link to="#"> 搜『{{ inputText }}』相关用户</router-link>
          ♐
        </p>
        <div class="box-all">
          <div class="box-item box-song" v-show="shows.songs">
            <h3><em>单曲</em></h3>
            <ul>
              <li v-for="(item,index) in result.songs" :key="index" @click="getUrl(item.id)">
                <span>
                  {{ item.name }}
                </span>
                <span>
                  {{ item.alias[0] == null ? '' : item.alias[0] }}
                </span>
              </li>
            </ul>
          </div>
          <div class="box-item box-artists" v-show="shows.artists">
            <h3><em>歌手</em></h3>
            <ul>
              <li v-for="(item,index) in result.artists" @click="goto('artistDetail',item.id)">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="box-item box-playlists" v-show="shows.playlists">
            <h3><em>专辑</em></h3>
            <ul>
              <li v-for="(item,index) in result.albums">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="box-item box-albums" v-show="shows.playlists">
            <h3><em>歌单</em></h3>
            <ul>
              <li v-for="(item,index) in result.songs">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import {search_suggest_keywords, song_url_v1} from '@/utils/api'

export default {
  name: "search",
  data() {
    return {
      inputText: '',
      result: {
        albums: [], //专辑
        artists: [], //歌手
        songs: [], //歌曲
        playlists: [], //歌单
        order: []
      },
      text_box: false,
      shows: {
        albums: false,
        artists: false,
        songs: false,
        playlists: false,
      }
    }
  },
  methods: {
    getUrl(id, e) {
      this.$message({showClose: true, message: '歌曲加载中...', duration: 1000});
      song_url_v1(id).then(res => {
        if (res.code === 403) {
          this.$message.error({showClose: true, message: '网络太拥挤了，稍后再试试吧', duration: 1000});
        } else if (res.code === 200) {
          if (e) {
            this.$store.state.MediaPlayer_show = true
          }
          this.$store.state.songId = id
          this.$store.state.songUrL = res.data[0].url
          this.$message.success({showClose: true, message: '歌曲加载成功！', duration: 1000});

        }

      }).catch(res => {
        this.$message.error({showClose: true, message: '网络太拥挤了，稍后再试试吧', duration: 1000});
      })
    },
    goto(type, text) {
      if (typeof text === "number" && !isNaN(text)) {
        if (type === "artistDetail" && text !== parseInt(this.$route.params.id)) {
          let urlS = '/artistDetail/' + text
          this.$router.push(urlS)
          this.inputText = null
        } else {
          this.$message({message: '当前已经是该歌手页面了哦~', duration: 1200});
        }
      } else {
        if (type === 'songsearch' && text !== null) {
          let urlS = '/songsearch/' + text

          this.$router.push(urlS)
          this.inputText = null

          this.text_box = false
        }
      }

    },
    box_show(a) {
      if (this.inputText != null) {
        if (a && this.inputText.length >= 1) {
          this.text_box = a
        } else if (!a) {
          setTimeout(() => {
            this.text_box = a
          }, 500)
        }
      }
    }
    ,
    getSearch(text) {
      if (text.length === 0 || text.split(" ").join("").length === 0) {
        this.text_box = false
        this.shows.albums = false
        this.shows.artists = false
        this.shows.songs = false
        this.shows.playlists = false
      } else {
        this.text_box = true
        search_suggest_keywords(text).then(res => {
          setTimeout(() => {
            for (let i = 0; i < res.result.order.length; i++) {
              switch (res.result.order[i]) {
                case 'albums':
                  this.result.albums = res.result.albums
                  this.shows.albums = true
                  break;
                case 'artists':
                  this.result.artists = res.result.artists
                  this.shows.artists = true
                  break;
                case 'songs':
                  this.result.songs = res.result.songs
                  this.shows.songs = true
                  break;
                case 'playlists':
                  this.result.playlists = res.result.playlists
                  this.shows.playlists = true
                  break;
              }
            }
          }, 100)


        })
      }
    }
  }
}
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}

.text-box {
  overflow: hidden;
  width: 80%;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #409eff;
  position: absolute;
  z-index: 9999999999;
}

.box-all {
  display: flex;
  flex-direction: column;
}

.box-all .box-item:nth-child(even) ul {
  background-color: #f9f8f8;
}

p {
  margin: 0;
  padding: 3px;
}

.note {
  border-bottom: 1px solid #e2e2e2;
}

.box-item h3 {
  width: 50px;
  text-align: center;
  font-size: 15px;
  margin: 0 5px;
  float: left;
}

.box-item ul {
  width: 100%;
  list-style: none;
  margin: 0 0 0 60px;
  padding: 5px 0;
  border-left: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.box-item li {
  cursor: pointer;
  padding: 5px 2px;
}

.box-item li:hover {
  background-color: #e7e7e7;
}

.box-item li > span:nth-child(1) {
  color: #409eff;
}

.router-link a {
  color: #cecece;
}
</style>

<style>
.search .text-box a {
  text-decoration: none;
  color: #7e7e7e;
}

.search .text-box a:hover {
  background-color: #c5c5c5;
}

.search .el-input__inner {
  border-radius: 100px !important;
}

.search .i_search {
  width: 40px;
  height: 40px;
  color: #000;
  background-color: #d1d1d1;
  border-radius: 50%;
  position: relative;
  left: 5px;
}
</style>
