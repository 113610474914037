<template>
  <div class="Headers">
    <el-row :gutter="20">
      <el-col :span="4" title="返回">
          <div  @click="goback" class="grid-content bg-purple">
            <i class="el-icon-s-promotion"></i>
          </div>
      </el-col>
      <el-col :span="5">
          <router-link to="/home" class="grid-content bg-purple">
            <div>首页</div>
          </router-link>
      </el-col>
      <el-col :span="5">
<!--        路由-->
        <router-link to="/list/19723756" class="grid-content bg-purple">
          <div>榜单</div>
        </router-link>
      </el-col>
      <el-col :span="5">
        <router-link to="/SongList" class="grid-content bg-purple">
          <div>歌单</div>
        </router-link>
      </el-col>
      <el-col :span="5">
        <router-link to="/Singer" class="grid-content bg-purple">
          <div>歌手</div>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Headers",
  methods:{
    goback(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.el-row {
  margin: 5px !important;
}

.el-col {
  border-radius: 4px;
}

.el-col a{
  text-decoration: none;
}
.bg-purple {
  color: #ffffff;
  background: #409eff;
  border: 1px solid #e1e1e1;
}

.router-link-active{
  text-decoration: none;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;
}

.active,.bg-purple:hover{
  color: #409eff;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0 #e7e7e7, -2px -2px 4px 0 #f7f7f7;
}
</style>