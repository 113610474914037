import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        songId: '',
        songName: '等待播放',
        songImg: 'https://img.soogif.com/DIqhMVRGNcMI356N4PtBHj3EudMAXjDo.gif_s400x0',
        songUrL: '',
        songTime: '',
        MediaPlayer_show: false,
        lrcTextData:{},
        lrcText:''
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
