<template>
  <div class="LrcText " v-if="this.$store.state.lrcText!==''">
    {{ this.$store.state.lrcText }}
  </div>
</template>

<script>
export default {
  name: "LrcText",
}
</script>

<style scoped>
.LrcText {
  z-index: 9999999999999;
  width: 70%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 1px 3px;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  transition: linear 0.25s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  transition: linear 0.3s;
}

.LrcTextactive {
  bottom: 20px;
}

@media screen and (max-width: 700px) {
  .LrcText {
    width: 61%;
    left: 63%;
    bottom: 70px;
  }
  .LrcTextactive {
    bottom: 20px;
  }
}
</style>
