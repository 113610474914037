<template>
  <div class="FooterMusic FooterMusicactive" :style="' background-image: url('+this.$store.state.songImg+')'">
    <div class="Footer-tou">
      <div class="el-image-box"  @click="MediaPlayer_show">
        <el-image :style="'transform: rotate('+content+'deg)'" :src="this.$store.state.songImg"
                  @click="transformBox()"></el-image>
      </div>
      <div style="text-align: center;">
        <i class="iconfont icon-houtui"></i>
        <i id="Footer-button" class="iconfont icon-bofang" @click="play()"></i>
        <i class="iconfont icon-kuaijin"></i>
      </div>
    </div>
    <div class="Footer-box">
      <div style="color: #fff">
        <span class="song-title"   @click="MediaPlayer_show">
          {{ this.$store.state.songName }}
        </span>
        <span class="song-time">{{ timeText }}</span>
      </div>
      <div>
        <el-slider v-model="percentage"
                   :show-tooltip="false"
                   @mousedown="this.$parent.isDraging=true"
                   @mouseup="this.$parent.isDraging=false"
                   @change="currentRater"></el-slider>
        <!--        <el-progress :percentage="percentage" :show-text="false" ></el-progress>-->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "FooterMusic",
  data() {
    return {
      percentage: 0,
      timeText: '',
      content: 0,
      transform_Box: false
    }
  },
  methods: {
    MediaPlayer_show() {
      this.$store.state.MediaPlayer_show = !this.$store.state.MediaPlayer_show
    },
    play() {
      this.$parent.play()
    },
    outPercentage(time, a, b) {
      if (isNaN(time)) {
        this.percentage = 0

      } else {
        this.percentage = time
      }

      this.timeText = a + '/' + b
    },
    currentRater(newVal) {
      this.$parent.currentRateChange(newVal)
    },
    transformBox() {
      if (this.transform_Box) {
        $('.FooterMusic').addClass('FooterMusicactive')
        $('.LrcText').addClass('LrcTextactive')
      } else {
        $('.FooterMusic').removeClass('FooterMusicactive')
        $('.LrcText').removeClass('LrcTextactive')
      }
      this.transform_Box = !this.transform_Box
    }
  }
}
</script>

<style scoped>
.FooterMusic {
  width: 90%;
  height: 50px;
  /*background: #8a8a8a;*/
  z-index: 99999;
  display: flex;
  align-items: center;
  border-radius: 5px;

  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);

  backdrop-filter: blur(5px);
  background-size: auto;
  background-position: center;
  transition: 0.3s ease-in-out;
}

.FooterMusicactive {
  left: 45%;
  transform: translate(-50%, 70px);
}

.FooterMusicactive .el-image {
  position: absolute;
  bottom: 60px;
  right: 60px;
  width: 100px;
  height: 100px;
  border-radius: 200px;
  transition: 0.5s linear;
}

.FooterMusic::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  filter: blur(-6px);
  backdrop-filter: blur(10px);
}


.Footer-tou {
  position: relative;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.el-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  bottom: 5px;
  cursor: pointer;
  transition: 0.25s linear;
  box-shadow: 0 0 1px 3px #ffffff;
}

.active .el-image {
  transition: 0.5s linear;
  /*animation: active-img 2s linear infinite;*/
}

.Footer-tou i {
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: #ffffff;
  margin: 0 5px;
  font-size: 30px;
}

.Footer-tou i:hover {
  color: #409eff;
}

.Footer-box {
  flex: 0.9;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>


<style>
.FooterMusic *{
  user-select: none;
}
.FooterMusic .el-slider__runway {
  margin: 0;
}

.FooterMusic .el-slider__button {
  width: 10px;
  height: 10px;
}

.song-title {
  cursor: pointer;
  font-size: 18px;
}

.song-time {
  margin-left: 5px;
  color: #409eff;
  font-size: 14px;
}

@media screen and (max-width: 700px) {
  .song-title {
    display: inline-block;
    max-width: 100px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .FooterMusicactive .el-image {
    width: 80px !important;
    height: 80px !important;
    border-radius: 200px;
    right: 80px !important;
  }
}

</style>
