<template>
  <div id="app">

    <nav>
      <MediaPlayer ref="MediaPlayer"></MediaPlayer>
    </nav>

    <audio crossOrigin='anonymous' :src="$store.state.songUrL" autoplay ref="Ref_audioPlayer" @ended="overAudio"
           @pause="onPause"
           @play="onPlay"
           @loadeddata="loadeddata"
           @timeupdate="timeupdate"></audio>

    <nav>
      <Headers/>
    </nav>
    <div class="box">
      <search ref="Search"></search>

      <router-view/>
    </div>

    <nav>
      <FooterMusic ref="FooterMusic"/>
    </nav>
    <nav>
      <playlist/>
    </nav>

    <LrcText></LrcText>
  </div>
</template>

<script>
import search from "@/views/HomeView/search";
import Headers from "@/views/Headers/Headers";
import FooterMusic from "@/views/FooterMusic/FooterMusic";
import MediaPlayer from "@/views/MediaPlayer/MediaPlayer";
import '@/assets/css/app.css'
import '@/assets/font_icon/iconfont.css'
import $ from "jquery";
import {song_detail, test} from '@/utils/api'
import localStorage from "@/utils/localStorage/localStorage";
import playlist from "@/views/playlist/Playlist";
import LrcText from "@/views/HomeView/LrcText";


export default {
  name: "app",
  components: {
    search,
    Headers,
    FooterMusic,
    MediaPlayer,
    playlist,
    LrcText

  },
  data() {
    return {
      playstatus: false,
      isDraging: false,
      marks: {
        0: "0",
        100: "100",
      },
      currentRate: 0,
      songData: {songId: '', songName: '', songImg: '', songUrL: '', songTime: '',},
      grooveData: {
        isInit: false,
        analyser: '',
        randomData: '',
        audio: '',
        cvs: '',
        ctx: '',
      },
      canvasData: {
        audioCtx: '',
        source: ''
      }
    }
  },
  created() {
    if (localStorage.get('music')) {
      this.songData = localStorage.get('music')
      setTimeout(() => {
        this.$refs.Ref_audioPlayer.currentTime = this.songData.songTime
      }, 50)

      this.$store.state.songId = this.songData.songId
      this.$store.state.songName = this.songData.songName
      this.$store.state.songImg = this.songData.songImg
      this.$store.state.songUrL = this.songData.songUrL
      this.$store.state.songTime = this.songData.songTime


      setTimeout(() => {
        this.$refs.Search.getUrl(this.songData.songId, false)
        this.$refs.Ref_audioPlayer.pause();
      }, 10)

      if (!this.playstatus) {
        // this.$refs.Ref_audioPlayer.pause();
      }
    }

    test()
        .then(res => {
          this.$message({
            message: '接口请求成功！',
            type: 'success',
            duration: 1500
          });
        })
        .catch(res => {
          this.$message.error({
            message: '接口请求出错！！请稍后再试...',
            duration: 2000
          });
          alert('即将打开接口页面，如果有数据即可返回\n如果没数据那就是接口嘎了~~\n\n白衣少年官群：612649814')
          window.open('https://api.toolka.cn')
        })
  },
  methods: {
    // 监听audio标签的音频加载完成事件
    loadeddata() {
      // 需等音频加载完成，才能获取到音频的时长
      song_detail({'ids': this.$store.state.songId}).then(res => {
        this.$store.state.songName = res.songs[0].name
        this.$store.state.songImg = res.songs[0].al.picUrl

      })

      this.marks["100"] = this.s_to_MS(this.$refs.Ref_audioPlayer.duration);
    },

    // 监听audio标签的播放事件
    onPlay() {
      $('.el-image-box').addClass('active')
      $('#Footer-button').addClass('icon-zanting').removeClass('icon-bofang')

      this.$refs.FooterMusic.rhythm = true
      this.playstatus = true;
      this.$refs.MediaPlayer.active_img = true

      this.GrooveLoad()
    },

    // 监听audio标签的暂停事件
    onPause() {
      $('.el-image-box').removeClass('active')
      $('#Footer-button').addClass('icon-bofang').removeClass('icon-zanting')

      this.playstatus = false;
      this.$refs.MediaPlayer.active_img = false

    },

    // 监听audio标签的停止播放事件
    overAudio() {
      $('#Footer-button').addClass('icon-bofang').removeClass('icon-zanting')

      this.playstatus = false;

    },

    // 监听audio标签的播放进度改变事件
    timeupdate(e) {
      if (!this.isDraging) {
        this.currentRate = Math.floor((e.target.currentTime / this.$refs.Ref_audioPlayer.duration) * 1000) / 10;

        this.marks["0"] = this.s_to_MS(e.target.currentTime);
      }

      this.$refs.MediaPlayer.content += 2
      this.$refs.FooterMusic.content += 2
      this.$refs.FooterMusic.outPercentage(this.currentRate, this.marks["0"], this.marks["100"]);

      if (this.$store.state.songId !== '' && e.target.currentTime !== 0) {
        localStorage.set('music', {
          'songId': this.$store.state.songId,
          'songName': this.$store.state.songName,
          'songImg': this.$store.state.songImg,
          'songUrL': this.$store.state.songUrL,
          'songTime': e.target.currentTime
        })
      }

      let time = e.target.currentTime

      time += 0.5
      let index = 0

      let lrc = this.$store.state.lrcTextData
      if (typeof lrc == "string") {
        let lrcData = lrc.split('\n').filter((s) => s).map((s) => {
          const parts = s.split(']');
          const timeParts = parts[0].replace('[', '').split(':');
          return {
            time: +timeParts[0] * 60 + +timeParts[1],
            words: parts[1],
          };
        })
        index = lrcData.findIndex((lrc) => lrc.time > time) - 1;

        if (index >= 0) {
          this.$store.state.lrcText = lrcData[index].words
        } else {
          this.$store.state.lrcText = ""
        }

      }

    },

    // 秒数转换分+秒
    s_to_MS(s) {
      let m;
      m = Math.floor(s / 60);
      s = Math.floor(s % 60);
      m += "";
      s += "";
      s = s.length === 1 ? "0" + s : s;
      if (isNaN(m)) {
        return "∞";
      }
      return m + ":" + s;
    },

    // 播放 / 暂停
    play() {
      if (this.playstatus) {

        this.$refs.Ref_audioPlayer.pause();
        this.$refs.MediaPlayer.active_img = false

        return false
      } else {

        this.$refs.Ref_audioPlayer.play();
        this.$refs.MediaPlayer.active_img = true

        return true
      }
    },

    //点击/拖拽原创面板进度条
    currentRateChange(newVal) {
      this.$refs.Ref_audioPlayer.currentTime = Math.round(
          (newVal / 100) * this.$refs.Ref_audioPlayer.duration
      );
      this.$refs.Ref_audioPlayer.play();
    },


    //律动初始化
    GrooveLoad() {
      this.grooveData.audio = document.querySelector('audio');
      this.grooveData.audio.crossOrigin = 'anonymous';

      this.grooveData.cvs = document.querySelector('canvas');
      this.grooveData.ctx = this.grooveData.cvs.getContext('2d');
      if (this.grooveData.isInit) {
        return;
      }
      this.canvasData.audioCtx = new AudioContext();
      this.canvasData.source = this.canvasData.audioCtx.createMediaElementSource(this.grooveData.audio);
      this.grooveData.analyser = this.canvasData.audioCtx.createAnalyser()
      this.grooveData.analyser.fftSize = 512;
      this.grooveData.randomData = Uint8Array.from(new Uint8Array(this.grooveData.analyser.frequencyBinCount), (v, k) => k);
      this.canvasData.source.connect(this.grooveData.analyser);
      this.grooveData.analyser.connect(this.canvasData.audioCtx.destination);

      this.grooveData.isInit = true;
      this.Groove()
    },

    //音乐律动
    Groove() {
      let randomData = this.grooveData.randomData
      let canvasDom = document.querySelector(".PlayerCanvas");

      if (document.documentElement.clientWidth > 800) {
        canvasDom.width = 610;
        canvasDom.height = 610;
      } else {
        canvasDom.width = 450;
        canvasDom.height = 450;
      }
      let ctx = canvasDom.getContext("2d");

      this.grooveData.analyser.getByteFrequencyData(randomData);
      randomData.sort(() => Math.random() - 0.5);
      const bData = [];
      let i = 0
      // let randomData=this.grooveData.randomData
      randomData.forEach(randomData => {
        if (i < 180) {
          i++
          bData.push(randomData);
        }
      })

      const angle = Math.PI * 2 / bData.length;
      ctx.clearRect(0, 0, canvasDom.width, canvasDom.height);
      ctx.fillStyle = 'rgba(255,2555,255,1)'; //设置颜色

      ctx.save();
      ctx.translate(canvasDom.width / 2, canvasDom.height / 2);

      bData.forEach((randomData, index) => {
        ctx.save();
        ctx.rotate(angle * index);
        ctx.beginPath();
        const h = randomData / 256 * 50;


        if (typeof ctx.roundRect != "undefined") {
          if (document.documentElement.clientWidth > 800) {
            ctx.roundRect(-2, 220, 2, (h < 2) ? 2 : h, 2);
          } else {
            ctx.roundRect(-2, 140, 2, (h < 2) ? 2 : h, 2);
          }
        } else {
          if (document.documentElement.clientWidth > 800) {
            ctx.roundRect(-2, 220, 2, (h < 2) ? 2 : h, 2);
          } else {
            ctx.roundRect(-2, 140, 2, (h < 2) ? 2 : h, 2);
          }
        }


        // ctx.fillRect(-4, 140,  4, (h < 4) ? 4 : h);
        ctx.fill();
        ctx.restore();
      });
      ctx.restore();

      if (this.grooveData.isInit) {
        requestAnimationFrame(this.Groove)
      }
    }

  },
}
</script>

<style>
.box {
  margin: auto;
  padding: 5px;
  width: calc(100vw - 30px);
  height: calc(100vh - 70px);
  border-radius: 10px;
  background-color: #409eff;
  overflow-y: auto;
}

</style>
