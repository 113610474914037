<template>
  <div class="playlist">
    <el-tooltip content="展开播放列表" placement="right">
    <span class="button" @click="drawer=true">
      ಠᴗಠ
    </span>
    </el-tooltip>

    <el-drawer title="播放列表" :visible.sync="drawer" :direction="direction">
      <div>
        <p style="text-align: center;">暂时还是空的...</p>
      </div>
    </el-drawer>

  </div>
</template>

<script>
export default {
  name: "playlist",
  data() {
    return {
      drawer: false,
      direction: 'ltr',
    }
  },
}
</script>

<style scoped>
.playlist {
  position: fixed;
  top: 75%;
  z-index: 9999;
}

.button {
  width: 50px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #409eff;
  background-color: #ffffff;
  display: block;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.button:hover {
  animation: buttonif 0.5s linear;

}

@keyframes buttonif {
  0% {
    width: 50px;
  }
  35% {
    width: 80px;
    color: #f7f7f7;
    border-radius: 0 100px 100px 0;
    background-color: #409eff;
    letter-spacing: 5px;
  }
  100% {
    width: 50px;
  }
}

</style>

<style>

@media screen and (max-width: 800px) {
  .playlist .el-drawer{
    width: 75% !important;
  }
  .el-tooltip__popper{
    display: none;
  }
}
</style>