import axios from "axios";
//网易云音乐地址
// const Api_URL='http://localhost:3000'
const Api_URL = 'https://api.toolka.cn'



// 请求头设置
// axios.defaults.headers.common['User-Agent'] = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36';

const instance = axios.create({
    Api_URL,
    timeout: 6000,
    withCredentials:true,
    changeOrigin: true,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }

});

//axios 超时时间
axios.defaults.timeout = 10000;
axios.defaults.withCredentials=true;

// 请求拦截器
instance.interceptors.request.use((config) => {
    if (localStorage._token) {
        config.headers.Authorization = localStorage._token;
    }
    return config;
});

//响应拦截器
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        // 错误状态码处理
        if (error.response) {
            let {status} = error.response
            switch (status) {
                case 404:
                    message.error(error.response.message)
                    break
                case 500:
                    message.error(error.response.statusText)
                    break
            }
        }
        return Promise.reject(error);
    }
);


const http = {
    /** get 请求
     * @param  {接口地址} url
     * @param  {请求配置} config
     */
    async get(url, config) {
        return new Promise((resolve, reject) => {
            instance
                .get(Api_URL + url, {params: config})
                .then((response) => {
                    if (response) resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /** post 请求
     * @param  {接口地址} url
     * @param  {请求数据} data
     * @param  {配置} config
     */
    async post(url, data, config) {
        return new Promise((resolve, reject) => {
            instance
                .post(Api_URL + url, data, config)
                .then((response) => {
                    console.log(response);
                    if (response) resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /** delete 请求
     * @param  {接口地址} url
     * @param  {请求配置} config
     */
    async delete(url, config) {
        return new Promise((resolve, reject) => {
            instance
                .delete(Api_URL + url, {
                    data: config,
                })
                .then((response) => {
                    if (response) resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** put 请求
     * @param  {接口地址} url
     * @param  {请求配置} config
     */
    async put(url, config) {
        return new Promise((resolve, reject) => {
            instance
                .put(Api_URL + url, config)
                .then((response) => {
                    if (response) resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default http;
