import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        // component: HomeView,
        component: () => import('@/views/Home/home'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/list/:id',
        name: 'list',
        // component: List,
        component: () => import('@/views/Home/List'),
        meta: {
            title: '排行榜'
        }
    },
    {
        path: '/SongList',
        name: 'SongList',
        // component: SongList,
        component: () => import('@/views/Home/SongList'),
        meta: {
            title: '歌单'
        }
    },
    {
        path: '/Singer',
        name: 'Singer',
        // component: Singer,
        component: () => import('@/views/Home/Singer'),
        meta: {
            title: '歌手'
        }
    },
    {
        path: '/artistDetail/:id',
        name: 'ArtistDetail',
        // component: ArtistDetail
        component: () => import('@/components/Singer/ArtistDetail'),
    },
    {
        path: '/songs/:id',
        name: '',
        component: () => import('@/components/Songs/Songs')
    }, {
        path: '/songsearch:text',
        name: '',
        component: () => ('@/components/Songsearch/Songsearch')
    }
]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes,
    mode: 'history'
})


export default router
