import Vue from 'vue'
import App from './App.vue'
import router from './utils/router/router'
import store from './utils/store/store'
import axios from "axios";
import jquery from "jquery";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)


Vue.config.productionTip = false
Vue.prototype.axios=axios
Vue.prototype.$ = jquery;

//router设置页面标题
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


